import {BrowserRouter, Routes, Route, Navigate, useLocation} from 'react-router-dom';
import Experiences from "./Experiences";
import Experience from "./Experience";
import Layout from "./Layout";
import BookNow from "./checkout/BookNow";
import Account from "./account/Account";
import Booking from "./account/Booking";
import UpdateDetails from "./account/UpdateDetails";
import CreateAccount from "./checkout/CreateAccount";
import Confirm from "./checkout/Confirm";
import Checkout from "./checkout/Checkout";
import Login from "./Login";
import ExperiencesAdminRouter from "./admin/AdminRouter";
import LogOut from "../LogOut";
import {useContext} from "react";
import AppContext from "../../context";

const ConfirmRedirect = () => {
    const location = useLocation();
    return <Navigate to={`../create-account${location.search}`} />;
};

const ExperiencesRouter = () => {
    const context = useContext(AppContext);

    return <BrowserRouter>
        <Routes>
            <Route path="log-out" element={<LogOut />} />
            {context.user && context.user.admin ? <Route path="/admin/*" element={<ExperiencesAdminRouter />} /> : null }
            <Route path="" element={<Layout />}>
                {context.user && !context.user.admin ?
                    <>
                        <Route path="/account/bookings/:id" element={<Booking />} />
                        <Route path="/account/update-details" element={<UpdateDetails />} />
                        <Route path="/account" element={<Account />} />
                    </>
                    : null
                }
                <Route path="/login" element={<Login />} />
                <Route path="/:id/checkout" element={<Checkout />}>
                    <Route path="book-now" element={<BookNow />} />
                    <Route path="create-account" element={<CreateAccount />} />
                    {context.user ?
                        <Route path="confirm" element={<Confirm />} />
                    :
                        <Route path="confirm" element={<ConfirmRedirect />} />
                    }
                    <Route path="" element={<Navigate to="book-now" />} />
                </Route>
                <Route path="/:id" element={<Experience />} />
                <Route path="/" element={<Experiences />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Route>
        </Routes>
    </BrowserRouter>
}

export default ExperiencesRouter