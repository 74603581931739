import {Link} from "react-router-dom";
import {useContext} from "react";
import AppContext from "../../../context";

const Header = () => {
    const context = useContext(AppContext);

    return <div className={"border-b border-borderGrey p-4 flex justify-between items-center"}>
        <Link to={'/'} className={"flex items-center gap-2"}>
            <div className={'block w-10 h-10 rounded-full bg-primary'}>
                <img src={`https://surecode.fra1.cdn.digitaloceanspaces.com/${process.env.REACT_APP_DOMAIN}/logo.svg`} alt={context.domain.name} />
            </div>
            <p>{context.domain.name}</p>
        </Link>
        {!context.user ?
            <Link to={"/login"} className={"border border-borderGrey px-3 py-1 rounded"}>Log In</Link>
        : context.user.admin ?
            <Link to={"/admin"} className={"border border-borderGrey px-3 py-1 rounded"}>Admin Dashboard</Link>
        :
            <Link to={"/account"} className={"border border-borderGrey px-3 py-1 rounded"}>My Account</Link>
        }
    </div>
}

export default Header