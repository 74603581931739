import { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AppContext from "../context";

import LogOut from './LogOut';

import Entry from './entry/Entry';
import Login from './entry/forms/Login';
import CreateAccount from './entry/forms/CreateAccount';
import VerifyEmailAddress from './entry/forms/VerifyEmailAddress';
import Induction from './entry/forms/Induction';
import RequestInduction from './entry/forms/RequestInduction';
import Terms from './entry/forms/Terms';
import ForgottenPassword from './entry/forms/ForgottenPassword';
import ResetPassword from './entry/forms/ResetPassword';

import Dashboard from './dashboard/Dashboard';
import Book from "./dashboard/book/Book";
import Confirm from "./dashboard/book/Confirm";
import Join from "./dashboard/book/Join";
import Bookings from "./dashboard/bookings/Bookings";
import Tokens from "./dashboard/tokens";
import Account from "./dashboard/account/Account";
import AddMinor from "./dashboard/account/AddMinor";
import Membership from "./dashboard/membership/Membership";
import PersonalAccessCode from "./dashboard/personalAccessCode/PersonalAccessCode";

import Admin from './admin/Admin';
import Users from './admin/users';
import AdminBookings from './admin/bookings';
import Products from './admin/products';
import MembershipPlans from "./admin/membershipPlans";
import Studios from './admin/studios';
import Withheld from './admin/studios/withheld';
import Transactions from './admin/transactions';
import Minors from './admin/minors';
import DiscountCodes from './admin/discountCodes';

import GiftVouchersTemplate from "./giftVouchers";
import GiftVouchers from "./giftVouchers/GiftVouchers";
import Checkout from "./giftVouchers/Checkout";

import NetworkError from "./NetworkError";
import {Network} from "@capacitor/network";
import Members from "./admin/members";
import Coupons from "./admin/coupons";
import ExperiencesRouter from "./experiences/Router";

function EntryRoutes() {
	return (
		<>
			<Route path="entry" element={<Entry />}>
				<Route path="login" element={<Login />} />
				<Route path="create-account" element={<CreateAccount />} />
				<Route path="forgotten-password" element={<ForgottenPassword />} />
				<Route path="reset-password/:token" element={<ResetPassword />} />
			</Route>
			<Route path="*" element={<Navigate to={`/entry/login?referer=${window.location.pathname+window.location.search}`} />} />
		</>
	)
}

function VerifyEmailAddressRoutes() {
	return (
		<>
			<Route path="entry" element={<Entry />}>
				<Route path="verify-email-address" element={<VerifyEmailAddress />} />
			</Route>
			<Route path="*" element={<Navigate to="/entry/verify-email-address" />} />
		</>
	)
}

function TermRoutes() {
	const context = useContext(AppContext);

	return (
		<>
			<Route path="entry" element={<Entry />}>
				{context.domain.videoInduction ?
					<Route path="induction" element={<Induction />} />
				:
					<Route path="request-induction" element={<RequestInduction />} />
				}
				<Route path="terms" element={<Terms />} />
			</Route>
			<Route path="*" element={context.domain.videoInduction ? <Navigate to="/entry/induction" /> : <Navigate to="/entry/request-induction" />} />
		</>
	)
}

function DashboardRoutes() {
	return (
		<>
			<Route exact path="dashboard" element={<Dashboard />}>
				<Route exact path="book" element={<Book />}>
					<Route exact path=":studio/sessions/:week" element={<Book />} />
				</Route>
				<Route exact path="book/:studio/confirm" element={<Confirm />} />
				<Route exact path="book/join/:shareLink" element={<Join />} />
				<Route exact path="bookings" element={<Bookings />} />
				<Route exact path="tokens" element={<Tokens.Template />}>
					<Route exact path="" element={<Tokens.List />} />
					<Route exact path="checkout/:id" element={<Tokens.Checkout />} />
				</Route>
				<Route exact path="settings/add-minor" element={<AddMinor />} />
				<Route exact path="settings" element={<Account />} />
			</Route>
			<Route path="*" element={<Navigate to="/dashboard/book" />} />
		</>
	)
}

function MembershipDashboardRoutes() {
	return (
		<>
			<Route exact path="dashboard" element={<Dashboard />}>
				<Route exact path="settings/add-minor" element={<AddMinor />} />
				<Route exact path="settings" element={<Account />} />
				<Route exact path="membership" element={<Membership />} />
				<Route exact path="personal-access-code" element={<PersonalAccessCode />} />
			</Route>
			<Route path="*" element={<Navigate to="/dashboard/membership" />} />
		</>
	)
}

function AdminRoutes() {
	return (
		<>
			<Route exact path="admin" element={<Admin />}>
				<Route exact path="bookings" element={<AdminBookings.Template />}>
					<Route exact path="" element={<AdminBookings.List />} />
					<Route exact path=":id" element={<AdminBookings.Existing />} />
					<Route exact path="create" element={<AdminBookings.Create />} />
				</Route>
				<Route exact path="products" element={<Products.Template />}>
					<Route exact path="" element={<Products.List />} />
					<Route exact path="create" element={<Products.Create />} />
					<Route exact path=":id" element={<Products.Update />} />
				</Route>
				<Route exact path="users" element={<Users.Template />}>
					<Route exact path="" element={<Users.List />} />
					<Route exact path=":id" element={<Users.Existing />} />
				</Route>
				<Route exact path="minors" element={<Minors.Template />}>
					<Route exact path="" element={<Minors.List />} />
					<Route exact path=":id" element={<Minors.Existing />} />
				</Route>
				<Route exact path="transactions" element={<Transactions.Template />}>
					<Route exact path="" element={<Transactions.List />} />
					<Route exact path="create" element={<Transactions.Create />} />
					<Route exact path=":id" element={<Transactions.Existing />} />
				</Route>
				<Route exact path="studios" element={<Studios.Template />}>
					<Route exact path="" element={<Studios.List />} />
					<Route exact path="create" element={<Studios.Create />} />
					<Route exact path=":id" element={<Studios.Update />} />
					<Route exact path=":id/withheld/create" element={<Withheld.Create />} />
					<Route exact path=":id/withheld/:withheldId" element={<Withheld.Existing />} />
				</Route>
				<Route exact path="discount-codes" element={<DiscountCodes.Template />}>
					<Route exact path="" element={<DiscountCodes.List />} />
					<Route exact path="create" element={<DiscountCodes.Create />} />
					<Route exact path=":id" element={<DiscountCodes.Update />} />
				</Route>
			</Route>
			<Route path="*" element={<Navigate to="/admin/products" />} />
		</>
	)
}

function MembershipAdminRoutes() {
	return (
		<>
			<Route exact path="admin" element={<Admin />}>
				<Route exact path="members" element={<Members.Template />}>
					<Route exact path="" element={<Members.List />} />
					<Route exact path=":id" element={<Members.Existing />} />
				</Route>
				<Route exact path="membership-plans" element={<MembershipPlans.Template />}>
					<Route exact path="" element={<MembershipPlans.List />} />
					<Route exact path="create" element={<MembershipPlans.Create />} />
					<Route exact path=":id" element={<MembershipPlans.Update />} />
				</Route>
				<Route exact path="coupons" element={<Coupons.Template />}>
					<Route exact path="" element={<Coupons.List />} />
					<Route exact path="create" element={<Coupons.Create />} />
				</Route>
			</Route>
			<Route path="*" element={<Navigate to="/admin/members" />} />
		</>
	)
}

function Router() {
	const context = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [networkDisconnected, setNetworkDisconnected] = useState(false);
	const [connectionFailed, setConnectionFailed] = useState(false);

	Network.addListener('networkStatusChange', status => {
		status.connected ?  setNetworkDisconnected(true) : setNetworkDisconnected(false)
	});

	useEffect(async () => {
		try {
			await context.fetchDomain();
			await context.fetchUser();
			setLoading(false);
			setConnectionFailed(false)
		} catch(error) {
			if(error.response?.status === 401){
				await context.handle401()
				setConnectionFailed(false)
				setLoading(false);
			} else {
				setConnectionFailed(true);
				setLoading(false);
			}
		}
	}, []);

	if(networkDisconnected || connectionFailed) return <NetworkError />
	if(loading) return null;

	if(context.domain.experiencesMode) return <ExperiencesRouter />


	return (
		<BrowserRouter>
			<Routes>
				<Route path="log-out" element={<LogOut />} />
				<Route path="gift-vouchers" element={<GiftVouchersTemplate />}>
					<Route path="" element={<GiftVouchers />} />
					<Route path="checkout/:id" element={<Checkout />} />
				</Route>

				{!localStorage.getItem("token") ?
					EntryRoutes()
					: !context.user?.emailVerified ?
						VerifyEmailAddressRoutes()
						: !context.user?.terms ?
							TermRoutes()
							: context.user.admin ?
									context.domain.membershipMode ?
										MembershipAdminRoutes()
										:
										AdminRoutes()
								:
								 context.domain.membershipMode ?
									 MembershipDashboardRoutes()
									 :
									 DashboardRoutes()
				}
			</Routes>
		</BrowserRouter>
	)
}

export default Router;
