import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import AvailableTime from "../components/AvailableTime";
import InputWrapper from "../components/InputWrapper";
import Empty from "../components/Empty";
import {useContext, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Data from "../Data";
import moment from "moment";
import AppContext from "../../../context";

const Experience = () => {
    const context = useContext(AppContext);
    const { id } = useParams();
    if(!id) window.location.href = "/"

    const [experience, setExperience] = useState(null);
    const [date, setDate] = useState(null);
    const [tickets, setTickets] = useState(1);
    const [event, setEvent] = useState(null);

    useEffect(async () => {
        try {
            const experience = await Data.fetchExperienceById( { id})
            setExperience(experience.data)
        } catch (error) {
            window.location.href = "/"
        }
    }, []);

    if(!experience) return null

    const availableDates = experience.futureEvents.map(event => {
        return new Date(event.date).setHours(0, 0, 0, 0)
    })
    const isDisabled = (date) => {
        date = date.setHours(0, 0, 0, 0);
        return availableDates.indexOf(date) === -1;
    }

    const ticketsRemaining = experience.futureEvents.find(availableEvent => event === availableEvent._id)?.ticketsRemaining ?? 0
    const selectedDate = experience.futureEvents.filter(event => new Date(event.date).setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0, 0, 0))

    return (
        <div className={"p-8"}>
            <div className={"container mx-auto grid grid-cols-1 gap-4"}>
                <div className={"grid grid-cols-1 gap-4"}>
                    <InputWrapper label={"Date"} input={
                        <DayPicker
                            className={"border border-borderGrey rounded-md p-2 flex items-center justify-center"}
                            mode="single"
                            selected={date}
                            disabled={isDisabled}
                            onSelect={e => {
                                setDate(e)
                                setEvent(null)
                            }}
                        />
                    }/>
                </div>

                {date ? <div className={"grid grid-cols-1 gap-4"}>
                    <p>Available Times</p>

                    <div className={"grid grid-cols-1 lg:grid-cols-3 gap-4"}>
                        {selectedDate.length > 0 ? selectedDate.map(availableEvent => <AvailableTime _id={availableEvent._id} ticketsRemaining={availableEvent.ticketsRemaining} event={event} setEvent={setEvent} time={moment(availableEvent.date).format("HH:mm A")}/>) : <Empty>There aren't any times available for this day.</Empty>}
                    </div>
                </div> : null}

                {date && event ?
                    <>
                        <InputWrapper label={"Tickets"} input={
                            <select value={tickets} onChange={e => setTickets(e.target.value)}>
                                {[...Array(ticketsRemaining).keys()].map(i => (
                                    <option key={i + 1} value={i + 1}>
                                        {i + 1} ticket{i + 1 > 1 ? 's' : ''}
                                    </option>
                                ))}
                            </select>
                        }/>

                        <div>
                            <Link to={`/${id}/checkout/${context.user ? 'confirm' : 'create-account'}?event=${event}&tickets=${tickets}`}
                                  className={"inline-block bg-primary py-2 px-4 w-full lg:w-auto text-white text-center rounded-md border-0"}>Continue</Link>
                        </div>
                    </>
                : null }
            </div>
        </div>
    )
}

export default Experience