import Preview from "./components/Preview";
import {useContext, useEffect, useState} from "react";
import Empty from "./components/Empty";
import Data from "./Data";
import AppContext from "../../context";
import Meta from "../components/Meta";

const Experiences = () => {
    const context = useContext(AppContext);

    const [experiences, setExperiences] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(async () => {
        try {
            const experiences = await Data.fetchExperiences()
            setExperiences(experiences.data)
            setError(false)
        } catch (error) {
            setError(true)
        } finally {
            setLoading(false)
        }
    }, []);

    return (
        <>
            <Meta title={context.domain.heading} />
            <div>
                <div className={"w-full h-60 bg-primary flex items-center relative overflow-hidden"}>
                    <div className={"p-6 [&_*]:text-white container mx-auto z-10 relative"}>
                        <h1 className={"text-4xl"}>{context.domain.heading}</h1>
                        <p className={"mt-2"}>{context.domain.subHeading}</p>
                    </div>
                    {context.domain.backgroundImage ? <img src={context.domain.backgroundImage} alt={""} className={"absolute z-0 opacity-30 w-full h-full object-cover"}/> : null}
                </div>

                <div className={"p-6 lg:py-16 container mx-auto"}>
                    <h2 className={"mb-4"}>Experiences</h2>

                    {loading ?
                        <Empty>
                            <p>Loading...</p>
                        </Empty>
                        : error ?
                            <Empty>
                                <p>There was an error loading the experiences, please refresh the page.</p>
                            </Empty>
                            : experiences.length === 0 ?
                                <Empty>
                                    <p>We don't currently have any experiences available.</p>
                                </Empty>
                                :
                                <div className={"grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"}>
                                    {experiences.map((experience) => <Preview key={experience.id} experience={experience} />)}
                                </div>
                    }


                </div>
            </div>
        </>
    )
}

export default Experiences