import Header from "../components/Header";
import {AlertCircle, Delete, Download, Edit2, Edit3, ExternalLink, Plus, Trash, Trash2} from "react-feather";
import {NavLink, useParams} from "react-router-dom";
import InputWrapper from "../../components/InputWrapper";
import Form from "../components/Form";
import {useEffect, useState} from "react";
import Data from "../../Data";
import moment from "moment/moment";
import Meta from "../../../components/Meta";

const Booking = () => {
    const { id } = useParams();

    const [booking, setBooking] = useState( null);
    const [loading, setLoading] = useState(true);

    useEffect(async () => {
        try {
            const booking = await Data.fetchExperienceBooking({ id })
            console.log(booking)
            setBooking(booking.data)
        } catch(error){
            window.location.href = '/admin/bookings'
        } finally {
            setLoading(false)
        }
    }, []);

    if(!booking) return null

    return (
        <>
            <Meta title={`Ref: ${booking.reference}`} />
            <Header heading={booking.reference} backToLabel={"Bookings"} backToTo={"/admin/bookings"} />
            <div className={"p-6 grid grid-cols-1 gap-6"}>

                {/*<div className={"bg-red-200 text-white w-full rounded-lg drop-shadow-sm [&>*]:py-4 [&>*]:px-6"}>*/}
                {/*    <div className={"flex items-center gap-2 flex-wrap"}>*/}
                {/*        <AlertCircle size={20} className={"text-red-900"}/>*/}
                {/*        <p className={"text-red-900"}>This booking was cancelled by Tom Gough on March 5, 2023 at*/}
                {/*            14:34.</p>*/}
                {/*        <NavLink to={""} className={"text-red-900 underline flex items-center gap-1"}>Stripe Refund Reference:*/}
                {/*            ABCD1234 <ExternalLink size={16}/></NavLink>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className={"bg-white w-full rounded-lg border drop-shadow-sm [&>*]:py-4 [&>*]:px-6"}>
                    <div className={"my-2 flex justify-between items-center"}>
                        <p className={"text-lg"}>Booking Details</p>
                    </div>

                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Reference</p>
                        <p className={"text-textGrey col-span-2"}>{booking.reference}</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Tickets</p>
                        <p className={"text-textGrey col-span-2"}>{booking.tickets}</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Name</p>
                        <p className={"text-textGrey col-span-2"}>{booking.User.firstName} {booking.User.lastName}</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Email Address</p>
                        <p className={"text-textGrey col-span-2"}>{booking.User.email}</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Booked On</p>
                        <p className={"text-textGrey col-span-2"}>{moment(booking.bookedOn).format("MMM Do YYYY, H:mm A")}</p>
                    </div>
                </div>

                <div className={"bg-white w-full rounded-lg border drop-shadow-sm [&>*]:py-4 [&>*]:px-6"}>
                    <div className={"my-2 flex justify-between items-center"}>
                        <p className={"text-lg"}>Event Details</p>
                    </div>

                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Experience</p>
                        <NavLink to={`/admin/experiences/${booking.Event.Experience._id}`} className={"text-textGrey col-span-2 underline flex items-center gap-1"}>{booking.Event.Experience.name} <ExternalLink size={16}/></NavLink>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Date &amp; Time</p>
                        <NavLink to={`/admin/upcoming-events/${booking.Event._id}`} className={"text-textGrey col-span-2 underline flex items-center gap-1"}>{moment(booking.Event.date).format("MMM Do YYYY, H:mm A")} <ExternalLink size={16}/></NavLink>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Duration</p>
                        <p className={"text-textGrey col-span-2"}>{booking.Event.Experience.duration} minutes</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Location</p>
                        <p className={"text-textGrey col-span-2"}>{booking.Event.Experience.location}</p>
                    </div>
                </div>

                <div className={"bg-white w-full rounded-lg border drop-shadow-sm [&>*]:py-4 [&>*]:px-6"}>
                    <div className={"my-2 flex justify-between items-center"}>
                        <p className={"text-lg"}>Payment Information</p>
                    </div>

                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Ticket Price</p>
                        <p className={"text-textGrey col-span-2"}>&pound;{booking.ticketPrice} each</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Total Amount</p>
                        <p className={"text-textGrey col-span-2"}>&pound;{booking.totalPrice}</p>
                    </div>
                    <div className={"grid grid-cols-3 gap-2 items-start border-t"}>
                        <p>Stripe Payment Reference</p>
                        <NavLink to={""} className={"text-textGrey col-span-2 underline flex items-center gap-1"}>TBC <ExternalLink size={16}/></NavLink>
                    </div>
                </div>

                <div className={"bg-white w-full rounded-lg border drop-shadow-sm [&>*]:py-4 [&>*]:px-6"}>
                    <div className={"my-2"}>
                        <p className={"text-lg"}>Cancel Booking</p>
                        <p className={"text-textGrey"}>Cancelling this booking will trigger an automatic email to the email address listed above.</p>
                    </div>

                    <div className={"mt-2 flex gap-2 border-t"}>
                        <button onClick={() => window.alert("Cancelling bookings will not work until we're hooked up to Stripe.")}
                                 className={"border-0 bg-red-200 text-red-900 px-2.5 py-1.5 rounded-md hover:opacity-80 transition"}>Cancel</button>
                        <button onClick={() => window.alert("Cancelling bookings will not work until we're hooked up to Stripe.")}
                                 className={"border-0 bg-red-200 text-red-900 px-2.5 py-1.5 rounded-md hover:opacity-80 transition"}>Cancel + Full Refund</button>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Booking