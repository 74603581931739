import axios from "axios";

const config = {
    headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
    }
};

const fetchExperiences = () => {
    return axios.get(`${process.env.REACT_APP_API}/experiences?Domain=${process.env.REACT_APP_DOMAIN}`, config);
}

const fetchExperienceById = ({ id }) => {
    return axios.get(`${process.env.REACT_APP_API}/experiences/${id}?Domain=${process.env.REACT_APP_DOMAIN}`, config);
}

const createExperience = ({ name }) => {
    return axios.post(`${process.env.REACT_APP_API}/experiences`, { name }, config);
}

const updateExperience = ({ id, experience }) => {
    return axios.put(`${process.env.REACT_APP_API}/experiences/${id}`, experience, config);
}

const uploadImage = ({ formData, }) => {
    return axios.post(`${process.env.REACT_APP_API}/images`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        ...config
    });
}

const updateExperienceImage = ({ id, image }) => {
    return axios.put(`${process.env.REACT_APP_API}/experiences/${id}/image`, { image }, config);
}

const deleteExperience = ({ id }) => {
    return axios.delete(`${process.env.REACT_APP_API}/experiences/${id}`, config);
}

const fetchEventsByExperienceId = ({ id }) => {
    return axios.get(`${process.env.REACT_APP_API}/experiences/${id}/events`, config);
}

const createEvents  = ({ experienceId, dates }) => {
    return axios.post(`${process.env.REACT_APP_API}/events`, {experienceId, dates}, config);
}

const fetchAllEvents = () => {
    return axios.get(`${process.env.REACT_APP_API}/events`, config);
}

const fetchEventById = ({ id }) => {
    return axios.get(`${process.env.REACT_APP_API}/events/${id}`, config);
}

const updateSiteSettings = ({ name, heading, subHeading, backgroundImage, primaryColor }) => {
    return axios.put(`${process.env.REACT_APP_API}/domains`, { name, heading, subHeading, backgroundImage, primaryColor }, config);
}

const createExperienceBooking = ({ eventId, tickets }) => {
    return axios.post(`${process.env.REACT_APP_API}/experience-bookings`, { eventId, tickets }, config);
}

const fetchAllExperienceBookings = () => {
    return axios.get(`${process.env.REACT_APP_API}/experience-bookings`, config);
}

const fetchExperienceBooking = ({ id }) => {
    return axios.get(`${process.env.REACT_APP_API}/experience-bookings/${id}`, config);
}

const fetchExperienceBookingsByEventId = ({ id }) => {
    return axios.get(`${process.env.REACT_APP_API}/events/${id}/experience-bookings`, config);
}

export default {
    fetchExperiences,
    fetchExperienceById,
    createExperience,
    updateExperience,
    uploadImage,
    updateExperienceImage,
    deleteExperience,
    fetchEventsByExperienceId,
    createEvents,
    fetchAllEvents,
    fetchEventById,
    updateSiteSettings,
    createExperienceBooking,
    fetchAllExperienceBookings,
    fetchExperienceBooking,
    fetchExperienceBookingsByEventId
}